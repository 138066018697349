import $ from "jquery";
export var TimelineView = function () {
    if ($('.timeline-months').length > 0) {
        var infoCards = $('.timeline-months .info-card');
        // const colWidth = 100 / 12;
        var timelineYearView = $('.timeline-year-view');
        var monthsList = timelineYearView.data('months').split(',');
        var selectedYear = timelineYearView.data('selected-year');
        var tScrollWidth_1 = $('.timeline-year-view').get(0).scrollWidth;
        var tWidth_1 = $('.timeline-year-view').width();
        var unitColDivider_1 = 24;
        $.each(infoCards, function () {
            var from = $(this).data('from');
            // const to = $(this).data('up-to');
            var fromMonth = from.split('/')[0];
            // const toMonth = to.split('/')[0];
            // const range = parseInt(toMonth) - parseInt(fromMonth);
            if (tScrollWidth_1 > tWidth_1) {
                unitColDivider_1 = 24;
            }
            $(this).css({
                // width: 'calc(' + range * colWidth + '% + 75px)',
                // minWidth: 'fit-content',
                marginLeft: "calc(100% / ".concat(unitColDivider_1, " * ").concat(fromMonth, " - 100% / ").concat(unitColDivider_1, ")")
            });
            var thisIs = $(this);
            $('.info-card-header', $(this)).on('click', function () {
                $('.info-card-body', thisIs).slideToggle('top');
                if ($('.info-card-body', thisIs).is(":visible")) {
                    console.log(1);
                }
            });
        });
        setTablesColumns(timelineYearView, monthsList, selectedYear, tScrollWidth_1, tWidth_1);
        var slider_1 = document.querySelector('.timeline-year-view');
        var mouseDown_1 = false;
        var startX_1, scrollLeft_1;
        var startDragging = function (e) {
            mouseDown_1 = true;
            startX_1 = e.pageX - slider_1.offsetLeft;
            scrollLeft_1 = slider_1.scrollLeft;
        };
        var stopDragging = function () {
            mouseDown_1 = false;
        };
        slider_1.addEventListener('mousemove', function (e) {
            e.preventDefault();
            if (!mouseDown_1) {
                return;
            }
            var x = e.pageX - slider_1.offsetLeft;
            var scroll = x - startX_1;
            slider_1.scrollLeft = scrollLeft_1 - scroll;
        });
        // Add the event listeners
        slider_1.addEventListener('mousedown', startDragging, false);
        slider_1.addEventListener('mouseup', stopDragging, false);
        slider_1.addEventListener('mouseleave', stopDragging, false);
    }
};
var setTablesColumns = function (timelineYearView, monthsList, selectedYear, tScrollWidth, tWidth) {
    var status = true;
    var tHeader = function (givenYear) {
        var j = [];
        $.each(monthsList, function (k, v) {
            j.push("<div class=\"t-col\">".concat(v, "<br/>").concat(givenYear, "</div>"));
        });
        return j.join('');
    };
    // $('.timeline-months.t-header').html(tHeader(selectedYear));
    if (tScrollWidth > tWidth || status === true) {
        $('.timeline-year-view').css({ overflowX: 'scroll' });
        $('.timeline-months.t-header').append(tHeader(parseInt(selectedYear) + 1));
        $('.timeline-year-view .t-header, .timeline-year-view .t-body-bg').css({
            width: "calc(75px * 24)"
        });
        $('.timeline-year-view .timeline-months.t-body-bg').css({
            backgroundSize: 'calc((100% - 60px) / 24 + 5px) 100%'
        });
        var colWidth = 100 / 6;
        for (var j = 1; j < 13; j++) {
            var foo = colWidth * j + '%';
            $('.timeline-year-view .info-card' + j).css({
                width: foo
            });
        }
    }
};
