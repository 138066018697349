export var ProjectsEnableDisable = function () {
    var enableDisableSwitch = document.querySelector('input[name="enable_disable_project"]');
    var language = document.getElementById("language-code").value;
    if (enableDisableSwitch) {
        enableDisableSwitch.addEventListener("click", function () {
            var projectId = enableDisableSwitch.dataset.projectId;
            var url;
            if (language === "fr") {
                url = "/fr/avis/activer-desactiver/projet/" + projectId + "/";
            }
            else {
                url = "/en/notices/enable-disable/project/" + projectId + "/";
            }
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    project_id: projectId,
                }),
            })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.success) {
                    enableDisableSwitch.checked = data.is_enabled;
                    window.location.reload();
                }
            })
                .catch(function (error) { return console.error(error); });
        });
    }
    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
};
