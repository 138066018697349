import $ from 'jquery';
export var Tooltip = function () {
    $('.nx-tooltip-icon').on('mouseenter', function () {
        var id = $(this).data('tooltip-target');
        var txtBox = $('#' + id);
        txtBox.show();
    }).on('mouseleave', function () {
        var id = $(this).data('tooltip-target');
        var txtBox = $('#' + id);
        txtBox.hide();
    });
    $('.tooltip-icon').on('click', function () {
        var id = $(this).data('tooltip-target');
        var txtBox = $('#' + id);
        if (txtBox.is(':visible')) {
            txtBox.hide();
        }
        else {
            txtBox.show();
        }
        $('.tooltip-close').on('click', function () {
            txtBox.hide();
        });
    });
};
