var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
var firebaseConfig = {
    apiKey: "AIzaSyAuhVATljFGNpJhFMQrLrG3oZASs7Rdk_8",
    authDomain: "plan-nord-prototype.firebaseapp.com",
    projectId: "plan-nord-prototype",
    storageBucket: "plan-nord-prototype.appspot.com",
    messagingSenderId: "420774834516",
    appId: "1:420774834516:web:e896f07190ff0ae56318c1",
    measurementId: "G-FHGRK0L6DW",
};
export var initFcmServiceWorker = function () { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                firebase.initializeApp(firebaseConfig);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, navigator.serviceWorker.register("".concat(window.location.origin, "/firebase-messaging-sw.js"))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_1 = _a.sent();
                console.log("ServiceWorker registration failed:", e_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var subscribeFcmNotifications = function (convoClient) { return __awaiter(void 0, void 0, void 0, function () {
    var messaging, fcmToken, csrfToken, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                messaging = firebase.messaging();
                return [4 /*yield*/, messaging.getToken()];
            case 1:
                fcmToken = _a.sent();
                if (!fcmToken) {
                    console.log("FcmNotifications: can't get fcm token");
                    return [2 /*return*/];
                }
                csrfToken = document.cookie.match(/csrftoken=([\w-]+)/)[1];
                return [4 /*yield*/, fetch("/api/notifications/register_device_token/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrfToken,
                        },
                        body: JSON.stringify({ token: fcmToken }),
                    })];
            case 2:
                response = _a.sent();
                if (response.ok) {
                    // console.log("FCM token registered");
                }
                else {
                    console.log("Error registering FCM token:", response.statusText);
                }
                convoClient.setPushRegistrationId("fcm", fcmToken);
                messaging.onMessage(function (payload) {
                    console.log("FcmNotifications: push received", payload);
                    //This is refresh event from Twillio, we need to skip it
                    if ("data" in payload &&
                        payload.data.twi_message_type == "twilio.channel.consumption_update") {
                        return;
                    }
                    // Handle Twillio New message received
                    if (convoClient &&
                        "data" in payload &&
                        payload.data.twi_message_type == "twilio.conversations.new_message") {
                        convoClient.handlePushNotification(payload);
                    }
                });
                return [2 /*return*/, messaging];
        }
    });
}); };
export var showNotification = function (pushNotification) {
    var _a;
    var notificationTitle = "Plan nord";
    console.log(pushNotification.body);
    var notificationOptions = {
        body: (_a = pushNotification.body) !== null && _a !== void 0 ? _a : "",
        icon: "https://plannord.nyc3.digitaloceanspaces.com/plannord/static/vendors/images/favicon.ico",
    };
    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function (event) {
        event.preventDefault();
        notification.close();
    };
};
